/**
 * System class
 * Mettre ici des infos sur le système, des getters, faites les manipulations ailleurs
 *
 * @author Stef Funaro
 *
 */
import configs from "./Configs";

export abstract class System {
	private static _cssVariables: CSSStyleDeclaration;

	public static get isHttps (): boolean {
		return (document.location.protocol === "https:");
	}

	public static get isMobile (): boolean {
		const query = window.matchMedia(`only screen and (max-width: ${configs.mobileScreenSize}px)`);
		return query.matches;
	}

	/**
	 * get all css variables
	 */
	public static get cssVariables (): CSSStyleDeclaration {
		if (this._cssVariables == null) {
			this._cssVariables = window.getComputedStyle(document.body);
		}
		return this._cssVariables;
	}

	/**
	 * get css variable by name
	 * @param name
	 */
	public static getCssVariable (name: string): string {
		return this.cssVariables.getPropertyValue(`--${name}`);
	}

	/**
	 * Get hash value in URL
	 * return string | boolean
	 */
	public static get urlHash (): string | boolean {
		let value: string | boolean = false;
		const hash = location.hash;
		if (hash) { value = location.hash.substr(1); }
		return value;
	}
}
