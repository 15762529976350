/**
 * Layout class
 * Mettre ici les trucs généraux qui concernent le layout.
 *
 * @author Stef Funaro
 *
 */
import { App } from "../App";
import { ThrottleHelper } from "../helpers/ThrottleHelper";
import { System } from "../core/System";
import { MatchHeight } from 'js-match-height';

export class Layout extends EventTarget {
	// Leave at off if you don't use it for better performance
	private readonly _checkBreakpointChangeOnResize: boolean = true;
	protected _lastBreakpoint: string | null = null;
	protected _currentBreakpoint: string | null = null;
	protected _breakpointsNamesArray: string[];
	protected _winW: number;
	protected _viewportW: number;
	protected _winH: number;
	protected _viewportH: number;
	public ON_DOC_RESIZE_EVENT: string = "layout-resize-event";
	public ON_BREAKPOINT_CHANGE_EVENT: string = "layout-breakpoint-change-event";

	constructor(protected app: App) {
		super();
	}

	init(): Layout {
		this._initBreakpoints();

		// Resize event (slowed down)
		window.addEventListener("resize", ThrottleHelper.throttle(this._handleDocResize, 1000, this));
		window.dispatchEvent(new Event("resize"));

		// on orientation change event
		window.addEventListener("orientationchange", this._handleDocResize.bind(this));

		const matchHeight = new MatchHeight('.matchHeight');

		this._doSpecialTricks();

		return this;
	}

	/**
	 * @returns {Number}
	 */
	public get winW(): Number {
		return this._winW;
	}

	/**
	 * @returns {Number}
	 */
	public get winH(): Number {
		return this._winH;
	}

	/**
	 * @returns {Number}
	 */
	public get viewportW(): Number {
		return this._viewportW;
	}

	/**
	 * @returns {Number}
	 */
	public get viewportH(): Number {
		return this._viewportH;
	}

	/**
	 * Is the viewport smaller than breakpoint ?
	 * @param breakpoint {string}
	 * @returns {boolean}
	 */
	public isUnderBreakpoint(breakpoint: string): boolean {
		return (this._viewportW < this[`bp_${breakpoint}`]);
	}

	/**
	 * is Breakpoint bigger or equal than breakpoint ?
	 * @param breakpoint {string}
	 * @returns {boolean}
	 */
	public isOverBreakpoint(breakpoint: string): boolean {
		return (this._viewportW >= this[`bp_${breakpoint}`]);
	}

	/***********************************************************
	 * PRIVATES
	 ************************************************************/

	/**
	 * @private
	 */
	private _doSpecialTricks(): void {
		// Automatic centering Vertical - Only once on load
		document.querySelectorAll(".js-center-v-once").forEach((element: HTMLElement, index) => {
			this._centerV(element);
		});

		// Automatic centering Horizontal - Only once on load
		document.querySelectorAll(".js-center-h-once").forEach((element: HTMLElement, index) => {
			this._centerH(element);
		});

		this._hackVisualComposer();
	}

	/**
	 * Hack de Visual Composer
	 * @private
	 */
	private _hackVisualComposer(): void {
		// TODO: Test hack VC
		// $(".vc_row").wrapInner("<div class='vc_row_inner'></div>");
		const items = document.querySelectorAll("div.vc_row");

		for (const item of items) {
			const wrapper = document.createElement("div");
			wrapper.className = "vc_row_inner";

			for (const itemContent of item.childNodes) {
				wrapper.appendChild(itemContent);
			}

			item.appendChild(wrapper);
		}
	}

	/**
	 * Centrage vertical dans son parent (dernier recours, essayez fort en css avant!)
	 * @private
	 */
	_centerV(element: HTMLElement): void {
		if (element.style.position === "static") {
			element.style.position = "relative";
		}
		// TODO: convert jquery center

		// let t = Math.floor($(this).parent().height() / 2) - $(this).height() / 2;
		// if (t < 0) {
		// 	t = 0;
		// }
		// element.style.top = t + "px";
	}

	/**
	 * Centrage horizontal dans son parent (dernier recours, essayez fort en css avant!)
	 * @private
	 */
	_centerH(element: HTMLElement): void {
		if (element.style.position === "static") {
			element.style.position = "relative";
		}
		// TODO: convert jquery center

		// let l = Math.floor($(this).parent().width() / 2) - $(this).width() / 2;
		// if (l < 0) {
		// 	l = 0;
		// }
		// element.style.left = l + "px";
	}

	/**
	 * Resize
	 */
	private _handleDocResize(): boolean {
		this._winW = window.innerWidth;

		this._winH = window.innerHeight;

		this._viewportW = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		this._viewportH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

		// Check breakpoint change
		if (this._checkBreakpointChangeOnResize) {
			this._lastBreakpoint = this._currentBreakpoint;
			// for (let i = 0; i < this._breakpointsNamesArray.length; i++) {
			for (let i = this._breakpointsNamesArray.length - 1; i >= 0; i--) {
				if (this._viewportW < this[`bp_${this._breakpointsNamesArray[i]}`]) {
					this._currentBreakpoint = this._breakpointsNamesArray[i];
				} else {
					break;
				}
			}
			if (this._currentBreakpoint !== this._lastBreakpoint) {
				this._onBreakpointChange();
			}
		}

		// Automatic centering Vertical
		document.querySelectorAll(".js-center-v").forEach((element: HTMLElement, index) => {
			this._centerV(element);
		});

		// Automatic centering Horizontal
		document.querySelectorAll(".js-center-h").forEach((element: HTMLElement, index) => {
			this._centerH(element);
		});

		const event: CustomEvent = new CustomEvent(this.ON_DOC_RESIZE_EVENT, { detail: { w: this._winW, h: this._winH, breakpoint: this._currentBreakpoint } });
		this.dispatchEvent(event);
		// this.dispatchEvent(this.ON_DOC_RESIZE_EVENT, this._winW, this._winH);

		return true;
	}

	/**
	 * Stockage de breakpoints du site
	 * Pris des variables dans le root du css (déclarées dans constants.scss)
	 * @private
	 */
	private _initBreakpoints(): boolean {
		// Breakpoints list
		const breakpointsFromCss = System.getCssVariable("breakpoints-names");
		this._breakpointsNamesArray = breakpointsFromCss.split(",");

		let bp;
		for (let i = 0; i < this._breakpointsNamesArray.length; i++) {
			bp = parseInt(System.getCssVariable(`breakpoint-${this._breakpointsNamesArray[i]}`), 10);
			this["bp_" + this._breakpointsNamesArray[i]] = (isNaN(bp)) ? 0 : bp;
		}
		return true;
	}

	/**
	 * Changement de breakpoint
	 * @private
	 */
	private _onBreakpointChange(): boolean {
		const event: CustomEvent = new CustomEvent(this.ON_BREAKPOINT_CHANGE_EVENT, { detail: { w: this._winW, h: this._winH, breakpoint: this._currentBreakpoint, lastBreakpoint: this._lastBreakpoint } });
		this.dispatchEvent(event);
		return true;
	}
}
