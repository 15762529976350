import * as bootstrap from "bootstrap";
window.Bootstrap = bootstrap; // Necessary for popper.js

import {App} from "./dx/App";
import "./features/gtag";

document.addEventListener("turbo:load", () => {
  const app = new App();
  setTimeout(function () {
    // Petite sécurité qui nous a réglé plusieurs problèmes random, doc ready + timeout de zero avant de starter.
    app.init();
  }, 0);

  // Fix AOS on page change
  document.querySelectorAll('.aos-init.aos-animate').forEach((element) => {
    element.classList.remove('aos-init');
    element.classList.remove('aos-animate');
  });
});

