/**
 * Navigation class
 * Mettre ici les trucs généraux qui concernent la navigation.
 *
 * @author Stef Funaro
 *
 */
import { App } from "../App";
import { System } from "../core/System";
import { ThrottleHelper } from "../helpers/ThrottleHelper";

import gsap from "gsap";

import * as bootstrap from "bootstrap";

export class Navigation {
  readonly _headerEl: HTMLElement;
  // readonly _mainNavContainer: HTMLElement;
  readonly _mainScroll: HTMLElement | null;
  readonly _mainNav: HTMLElement;
  private _burgerBtn: HTMLButtonElement;
  private _collapseNavEl: HTMLElement;
  private _mainNavIsCollapsable: Boolean;

  readonly _scrollToOffset: number = 300;
  readonly _navCollapseBreakpointSize: number;
  private _lastScroll: number = 0;

  private negativeDelta: number = 0;

  // Sticky
  private readonly _stickyNav: Boolean = false;
  private readonly _stickyNavEl: HTMLElement;
  // À quelle position de scroll, le style de la nav change
  private readonly _stickyNavStyleSwitchedPosition = 200;
  // À quelle position de scroll, la nav sticky se cache
  private readonly _stickyNavShowHidePosition = 400;
  private readonly _stickySubHeroNavShowHidePosition = 800;

  constructor(protected app: App) {
    this._headerEl = this.app.mainContainer.querySelector("header")!;
    if (this._headerEl === null) {
      return;
    }
    this._mainNav = this._headerEl.querySelector("#navbar-main")!;
    this._mainScroll = document.getElementById("main-scroll");
    if (this._mainNav === null) {
      return;
    }
    // Sticky nav
    const stickyNavEl: HTMLElement | null = this._headerEl.querySelector(".sticky-nav");
    if (stickyNavEl !== null) {
      this._stickyNav = true;
      this._stickyNavEl = stickyNavEl;
    }

    // About page -> scrollSpy for years
    if (document.querySelector("#navbar-annees")) {
      new bootstrap.ScrollSpy(this._mainScroll || window, {
        target: "#navbar-annees",
        offset: -300,
      });
    }

    // Fun fiable facile -> scrollSpy for years
    if (document.querySelector("#nav-fun-fiable-facile")) {
      new bootstrap.ScrollSpy(this._mainScroll || window, {
        target: "#nav-fun-fiable-facile",
        offset: -300,
      });
    }

    // Size ou la nav se collapse
    this._navCollapseBreakpointSize = parseInt(System.getCssVariable("navbar-collapse-size"), 10);
  }

  /******************************************
   * Public
   * ******************************************/

  init(): Navigation {
    this._fixTurboAnchorLinks();

    if (!this._mainScroll) {
      throw new Error("Cannot find main-scroll element");
    }

    // Resize event (slowed down)
    this._mainScroll.addEventListener(
      "scroll",
      ThrottleHelper.throttle((e) => this._handleScroll(this._mainScroll!.scrollTop, e), 100, this)
    );
    this._mainScroll.dispatchEvent(new Event("scroll"));

    window.addEventListener(
      "scroll",

      ThrottleHelper.throttle((e) => this._handleScroll(window.scrollY, e), 100, this)
    );

    window.dispatchEvent(new Event("scroll"));

    // Fix bootstrap scrollspy with turbo
    document.querySelectorAll('[data-bs-spy="scroll"]').forEach((dataSpyEl) => {
      bootstrap.ScrollSpy.getOrCreateInstance(dataSpyEl).refresh();
    });

    // S'il y a un hash, on y scroll en anim!
    if (location.hash) {
      this.scrollToElem(document.querySelector(location.hash));
    }

    // Burger
    this._setBurgerNav();

    //Mobile tabs
    const instance = this;
    const ongletsItems = this._mainNav.querySelectorAll("a.onglet-item");
    Array.from(ongletsItems).forEach((ongletsItem) => {
      ongletsItem.addEventListener("click", function (e) {
        e.preventDefault();
        const clickEl = e.currentTarget as HTMLElement;
        instance._mainNav.querySelector(".onglet-item.active")?.classList.remove("active");
        instance._mainNav.querySelector(".onglet-active")?.classList.remove("onglet-active");
        clickEl.classList.add("active");
        const targetID: string | undefined = clickEl.dataset.target;
        if (targetID !== undefined) {
          instance._mainNav.querySelector(targetID)?.classList.add("onglet-active");
        }
      });
    });

    return this;
  }

  public scrollToElem(elem: HTMLElement | null): void {
    if (this._mainScroll && elem) {
      const elemRect = elem.getBoundingClientRect();

      const scrollToOffset = (+(elem.dataset.scrollToOffset || 0) / 100) * window.innerHeight;

      const targetOffset = elemRect.top + this._mainScroll.scrollTop + (scrollToOffset || -this._scrollToOffset);

      this._mainScroll.scrollTo({ top: targetOffset, behavior: "smooth" });
    }
  }

  /******************************************
   * BURGER
   * ******************************************/

  /**
   * Burger Nav
   * @private
   */
  private _setBurgerNav(): void {
    // Burger btn exists ?
    const burgerBtn: HTMLButtonElement = this._headerEl.querySelector("button[data-toggle='collapse-henri']")!;
    if (burgerBtn === null) {
      return;
    }
    // Yes, keep going!
    this._burgerBtn = burgerBtn;
    // Collapsed nav exists ?
    const collapseNavElId = this._burgerBtn.dataset.target as string;
    const collapseNavEl: HTMLElement = this._headerEl.querySelector(collapseNavElId)!;
    if (collapseNavEl === null) {
      return;
    }
    // Yes, keep going!
    // GO!
    this._mainNavIsCollapsable = true;
    this._collapseNavEl = collapseNavEl;
    // Button click
    this._burgerBtn.addEventListener("click", (e) => {
      e.preventDefault();
      const target = e.currentTarget as Element;
      if (target.classList.contains("collapsed")) {
        // Is closed !
        target.classList.remove("collapsed");
        this.openBurgerNav();
      } else {
        // Is opened ?
        target.classList.add("collapsed");
        this.closeBurgerNav();
      }
    });
  }

  /**
   * Ouverture du menu burger
   */
  public openBurgerNav(): void {
    document.body.classList.add("opened-nav");
    this.app.mainContainer.classList.add("opened-nav");
    gsap.fromTo(this._collapseNavEl, 0.4, { opacity: 0 }, { opacity: 1, display: "block" });
  }

  /**
   * Fermerture du menu burger
   */
  public closeBurgerNav(): void {
    document.body.classList.remove("opened-nav");
    this.app.mainContainer.classList.remove("opened-nav");
    gsap.to(this._collapseNavEl, 0.4, {
      opacity: 0,
      display: "none",
      onComplete: (e) => {
        // Enlever l'opacity, parce qu'en resize, le display change par css avec !important et on veut pas de l'opacité dans les pattes
        this._collapseNavEl.style.opacity = "1";
      },
    });
  }

  /******************************************
   * Privates
   * ******************************************/

  /**
   * on scroll event
   * @private
   */
  private _handleScroll(scrollTop, event): void {
    if (!this._stickyNav) {
      return;
    }

    if (scrollTop > this._stickySubHeroNavShowHidePosition) {
      this.app.mainContainer.classList.add("nav-scrolled-second-sub-show");
    } else {
      this.app.mainContainer.classList.remove("nav-scrolled-second-sub-show");
    }

    let requiredHeight = this._stickyNavStyleSwitchedPosition;

    if (document.body.classList.contains("nav-scrolled")) {
      requiredHeight -= 100;
    }

    if (scrollTop > requiredHeight) {
      document.body.classList.add("nav-scrolled");
      this.app.mainContainer.classList.add("nav-scrolled");
    } else {
      document.body.classList.remove("nav-scrolled");
      this.app.mainContainer.classList.remove("nav-scrolled");
    }

    if (scrollTop < this._stickyNavShowHidePosition) {
      document.body.classList.remove("nav-scrolled-hide");
      this.app.mainContainer.classList.remove("nav-scrolled-hide");

      this._lastScroll = scrollTop;
      this.negativeDelta = 0;
      return;
    }

    if (scrollTop > this._lastScroll) {
      this.negativeDelta = 0;

      document.body.classList.add("nav-scrolled-hide");
      this.app.mainContainer.classList.add("nav-scrolled-hide");
      //Cette classe là ne s'enlève jamais.
      this.app.mainContainer.classList.add("nav-scrolled-hide-point-passed");
      this._lastScroll = scrollTop;

      return;
    }

    this.negativeDelta += this._lastScroll - scrollTop;
    this._lastScroll = scrollTop;

    if (this.negativeDelta > 20) {
      document.body.classList.remove("nav-scrolled-hide");
      this.app.mainContainer.classList.remove("nav-scrolled-hide");
    }
  }

  /**
   * Disable this behavior https://github.com/hotwired/turbo/issues/539#issuecomment-1051039406
   * which makes random full page visit when clicking anchor link with #.
   *
   * Turbo behavior: If visiting same anchor link as current, it does a full page visit.
   * Issue: Turbo last rendered location always represent the very first page visit so it appears to
   *        be a random bug while it ain't.
   * Workaround: Update internal object ourself.
   */
  private _fixTurboAnchorLinks() {
    window.addEventListener("hashchange", (event) => {
      // @ts-ignore
      window.Turbo.session.view.lastRenderedLocation.hash = new URL(event.newURL).hash;
    });

    /**
     * Make the scroll on click behave similarly to the page load
     */
    this.app.mainContainer.querySelectorAll("a[href^='#']:not([href='#'])").forEach((link: HTMLDivElement) => {
      link.addEventListener("click", (e) => {
        const element = e.target as HTMLAnchorElement;
        if (element) {
          const target = document.getElementById(new URL(element.href).hash.replace("#", ""));

          if (target) {
            e.preventDefault();
            history.replaceState(undefined, "", new URL(element.href));
            this.scrollToElem(target);
          }
        }
      });
    });
  }
}
