document.addEventListener("turbo:load", function() {
    const submissionQuote = document.getElementById("request-quote-form");

    if (submissionQuote) {
        submissionQuote.addEventListener('turbo:before-fetch-response', (e) => {
            const status = e.detail.fetchResponse.response.status;
            if(status === 200){
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'formSubmissionQuote',
                });
            }
        });
    }
});
