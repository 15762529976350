/**
 *
 * Liens de partage sociaux
 *
 * Exemples:
 *
 * URL OPTIONNELLE
 *
 * <a href="#" class="social-share-facebook" data-url="http://www.google.ca" >Partagez sur Facebook!</a>
 * <a href="#" class="social-share-facebook" >Partagez sur Facebook!</a>
 *
 * <a href="#" class="social-share-twitter" data-text="Lisez cet article: http://www.google.ca" >Partagez sur Twitter!</a>
 *
 * <a href="#" class="social-share-linkedin" data-url="http://www.google.ca"  data-text="Visitez cette page" >Partagez sur Linkedin!</a>
 *
 */
export class SocialShareLinks {
	/**
	 * init
	 */
	init (): void {
		// Facebook
		document.querySelectorAll(".social-share-facebook").forEach((link: HTMLDivElement, index: number) => {
			this.setFacebookShareLink(link);
		});

		// Twitter
		document.querySelectorAll(".social-share-twitter").forEach((link: HTMLDivElement, index: number) => {
			this.setTwitterShareLink(link);
		});

		// Linkedin
		document.querySelectorAll(".social-share-linkedin").forEach((link: HTMLDivElement, index: number) => {
			this.setLinkedinShareLink(link);
		});
	}

	/**
	 * Facebook share
	 * @param btn
	 */
	setFacebookShareLink (btn: HTMLElement): void {
		btn.addEventListener("click", function handleClick (event) {
			event.preventDefault();
			let url: string;
			if (btn.dataset.url !== undefined) {
				url = btn.dataset.url;
			} else {
				url = window.location.href;
			}
			const w = 575;
			const h = 400;
			const left = 0;
			const top = 0;
			const opts = `toolbar=0,status=0,width=${w},height=${h},top=${top},left=${left}`;
			const winUrl = "https://www.facebook.com/sharer/sharer.php?u=" + url;

			window.open(winUrl, "sharer", opts);
		});
	}

	/**
	 * Twitter share
	 * @param btn
	 */
	setTwitterShareLink (btn: HTMLElement): void {
		btn.addEventListener("click", function handleClick (event) {
			event.preventDefault();
			let text: string;
			if (btn.dataset.text !== undefined) {
				text = btn.dataset.text;
			} else {
				text = window.location.href;
			}

			const w = 575;
			const h = 400;
			const left = 0;
			const top = 0;
			const opts = `toolbar=0,status=0,width=${w},height=${h},top=${top},left=${left}`;
			const winUrl = "http://twitter.com/share?text=" + text;

			window.open(winUrl, "twitter", opts);
		});
	}

	/**
	 * Linkedin share
	 * @param btn
	 */
	setLinkedinShareLink (btn: HTMLElement): void {
		btn.addEventListener("click", function handleClick (event) {
			event.preventDefault();
			let url: string;
			if (btn.dataset.url !== undefined) {
				url = btn.dataset.url;
			} else {
				url = window.location.href;
			}
			let text: string;
			if (btn.dataset.text !== undefined) {
				text = btn.dataset.text;
			} else {
				text = window.location.href;
			}

			const w = 575;
			const h = 400;
			const left = 0;
			const top = 0;
			const opts = `toolbar=0,status=0,width=${w},height=${h},top=${top},left=${left}`;
			const winUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&summary=${text}&source=LinkedIn`;

			window.open(winUrl, "linkedin", opts);
		});
	}
}
