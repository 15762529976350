/**
 * Gradient animation
 */

import gsap from "gsap";
import * as R from 'remeda'

const debounce = (fn, ms) => {
    const fn_ = R.debounce(fn, { timing: "both", waitMs: ms })
    return (...args) => fn_.call(...args)
}

export class GradientsColors {

    readonly canvas: HTMLCanvasElement;
    readonly imageNode;
    public isImgLoaded: Boolean;
    public context: CanvasRenderingContext2D;
    public interval;
    public animPos = 0;
    private initColor1: { r: number; b: number; g: number };
    private initColor2: { r: number; b: number; g: number };
    private color1: { r: number; b: number; g: number };
    private color2: { r: number; b: number; g: number };
    private color1Str;
    private color2Str;
    private lastPrc = 50;
    private currentPrc = 50;
    private tween: gsap.core.Tween | null = null;
    private fullWidth = false;
    private scrollLess = false;
    private mode: 'default' | 'green' | 'red';

    constructor(el: HTMLCanvasElement, options: object) {
        const instance = this;
        this.canvas = el;
        this.context = this.canvas.getContext('2d')!;

        this.fullWidth = options["fullwidth"];
        this.scrollLess = options["scrollLess"];
        this.mode = options["mode"] || 'default';

        this.initColor1 = { r: 11, g: 157, b: 154 };
        this.initColor2 = { r: 181, g: 34, b: 86 };

        // Simplicity

        if(this.mode === 'green') {
            this.initColor2  = this.initColor1
        }

        if(this.mode === 'red') {
            this.initColor1 = this.initColor2;
        }


        this.color1 = this.initColor1;
        this.color2 = this.initColor2;

        this.color1Str = `rgb(${this.color1.r}, ${this.color1.g}, ${this.color1.b})`;
        this.color2Str = `rgb(${this.color2.r}, ${this.color2.g}, ${this.color2.b})`;

        if (options["image"]) {
            this.imageNode = new Image();
            this.imageNode.onerror = function () {
                throw new Error('Granim: The image source is invalid.');
            };
            this.imageNode.onload = function () {
                instance.isImgLoaded = true;
                instance.update();
            };
            this.imageNode.src = options["image"];
        }
        this.update();

        const mainScroll = document.getElementById("main-scroll")

        if (!mainScroll) {
            return
        }

        if(!this.scrollLess) {
            mainScroll.addEventListener("scroll", (event) => {
                instance.update();
            });
        }

        addEventListener("resize", debounce((event) => {
            instance.update();
        }, 100));
        

        addEventListener("mousemove", debounce((event) => {
            var mouseX = event.clientX;
            var viewportWidth = window.innerWidth;
            var mouseXPercentage = (mouseX / viewportWidth) * 100;

            instance.animateToPrc(mouseXPercentage);
        }, 100));

    }

    public update() {
        this.canvas.style.width = '100%';
        this.canvas.style.height = '100%';
        this.canvas.width = this.canvas.offsetWidth;
        this.canvas.height = this.canvas.offsetHeight;
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        if (this.imageNode) {
            this.context.imageSmoothingEnabled = true;
            this.drawImageProp(this.context, this.imageNode);
            // Apply the multiply blending mode
            this.context.globalCompositeOperation = 'multiply';
        }

        const rect = this.canvas.getBoundingClientRect();
        const mouseX = rect.left - this.canvas.width / 2;
        const mouseY = rect.top - this.canvas.height / 2;

        // Calculate the angle of rotation
        const rotationAngle = (this.fullWidth) ? 1 : Math.atan2(mouseY, mouseX);

        // Create the gradient with rotation
        const gradient = this.context.createLinearGradient(
            0, 0, Math.cos(rotationAngle) * this.canvas.width,
            Math.sin(rotationAngle) * this.canvas.height
        );

        gradient.addColorStop(0, this.color1Str);
        gradient.addColorStop(1, this.color2Str);

        // Apply the gradient color
        this.context.fillStyle = gradient;
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }


    public animateToPrc(prc) {
        if (prc > 100) {
            prc = 100
        }
        if (prc < 0) {
            prc = 0
        }

        this.animateTransition(this.lastPrc, prc);
    }

    public animateTransition(fromNumber, toNumber) {
        this.currentPrc = fromNumber;
        if (this.tween !== null) {
            this.tween.kill();
        }

        this.tween = gsap.to(this, 2, { "currentPrc": toNumber, onUpdate: this.doAnim, callbackScope: this });
    }

    public doAnim() {
        this.applyTransitionPrc(this.currentPrc);
    }

    public applyTransitionPrc(prc) {


        this.lastPrc = prc;
        this.color1Str = this.interpolateColors(this.initColor1, this.initColor2, (prc / 100));
        this.color2Str = this.interpolateColors(this.initColor2, this.initColor1, (prc / 100));
        this.update();
    }

    public drawImageParall() {

    }

    /**
     * Draw image (cover)
     * @param ctx
     * @param img
     * @param x
     * @param y
     * @param w
     * @param h
     * @param offsetX
     * @param offsetY
     */
    public drawImageProp(ctx, img, x?, y?, w?, h?, offsetX?, offsetY?) {
        if (arguments.length === 2) {
            x = y = 0;
            w = ctx.canvas.width;
            h = ctx.canvas.height;
        }

        // default offset is center
        offsetX = typeof offsetX === "number" ? offsetX : 0.5;

        offsetY = this.scrollLess ? 0.05 :  this.getElementPosition(this.canvas) / 100;

        // keep bounds [0.0, 1.0]
        if (offsetX < 0) offsetX = 0;
        if (offsetY < 0) offsetY = 0;
        if (offsetX > 1) offsetX = 1;
        if (offsetY > 1) offsetY = 1;

        var iw = img.width,
            ih = img.height,
            r = Math.min(w / iw, h / ih),
            nw = iw * r,   // new prop. width
            nh = ih * r,   // new prop. height
            cx, cy, cw, ch, ar = 1;

        // decide which gap to fill
        if (nw < w) ar = w / nw;
        if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
        nw *= ar;
        nh *= ar;

        // calc source rectangle
        cw = iw / (nw / w);
        ch = ih / (nh / h);

        cx = (iw - cw) * offsetX;
        cy = (ih - ch) * offsetY;

        // make sure source rectangle is valid
        if (cx < 0) cx = 0;
        if (cy < 0) cy = 0;
        if (cw > iw) cw = iw;
        if (ch > ih) ch = ih;

        // fill image in dest. rectangle

        ctx.drawImage(img, Math.round(cx), Math.round(cy), Math.round(cw), Math.round(ch), Math.round(x), Math.round(y), Math.round(w), Math.round(h));
    }

    public interpolateColors(startColor, endColor, progress) {
        const r = Math.round(startColor.r + (endColor.r - startColor.r) * progress);
        const g = Math.round(startColor.g + (endColor.g - startColor.g) * progress);
        const b = Math.round(startColor.b + (endColor.b - startColor.b) * progress);

        return `rgb(${r}, ${g}, ${b})`;
    }

    public getElementPosition(element) {
        const rect = element.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        const positionY = (rect.top / viewportHeight) * 100;

        return positionY;
    }
}