/**
 * Forms class
 * Mettre ici les trucs généraux qui concernent les formulaires.
 *
 * @author Stef Funaro
 *
 */
import { App } from "../App";

export class Forms {
	constructor(protected app: App) {
	}

	init(): Forms {
		return this;
	}
}
