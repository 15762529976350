const extractAttributes = (element, ...attrs) => {
  return attrs.reduce((prev, current) => ({ ...prev, [current]: element.getAttribute(current) }), {});
};

/**
 * Based on https://github.com/hotwired/turbo/issues/792
 */
Turbo.StreamActions.push_state = function () {
  const url = this.getAttribute("url");
  const state = { turbo_stream_history: true };
  Turbo.cache.exemptPageFromPreview();
  history.replaceState(state, "", window.location.href);
  history.pushState(state, "", url);
};

Turbo.StreamActions.scroll_to = function () {
  const { x, y, smooth } = extractAttributes(this, "x", "y", "smooth");

  const container = document.getElementById("main-scroll");

  container.scrollTo({ left: x, top: y, behavior: smooth === "true" ? "smooth" : "instant" });
};

Turbo.StreamActions.scroll_to_element = function () {
  const { element, smooth } = extractAttributes(this, "element", "smooth");

  const container = document.getElementById("main-scroll");
  const target = document.querySelector(element);

  container.scrollTo({ left: 0, top: target.scrollTop, behavior: smooth === "true" ? "smooth" : "instant" });
};

window.addEventListener("popstate", (event) => {
  if (event.state && event.state.turbo_stream_history) {
    Turbo.visit(window.location.href, { action: "replace" });
  }
});
